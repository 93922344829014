import type { ZudokuConfig } from "zudoku";
import React from "react";

const config: ZudokuConfig = {
  page: {
    logo: {
      src: {
        light: "/docs-static/logos/logo-light.svg",
        dark: "/docs-static/logos/logo-dark.svg",
      },
      width: "200px",
    },
  },
  theme: {
    light: {
      primary: "229 100% 65%",
      primaryForeground: "229 10% 6.5%",
      secondary: "-30 100% 84%",
      secondaryForeground: "-30 10% 8.4%",
    },
  },
  metadata: {
    title: "%s | 4intelligence",
    favicon: "https://4intelligence.ai/favicon.ico",
  },
  docs: {
    files: "/pages/**/*.{md,mdx}",
  },
  sitemap: {
    siteUrl: "https://zudoku.dev",
    outDir: "docs/",
  },
  redirects: [
    { from: "/", to: "/docs" },
    { from: "/docs/getting-started", to: "/docs/app-quickstart" },
  ],
  topNavigation: [
    { id: "docs", label: "Documentação" },
    { id: "api", label: "API" },
  ],
  sidebar: {
    docs: [
      {
        type: "category",
        label: "Início",
        icon: "sparkles",
        items: [
          "introduction",
          // "app-quickstart",
          // "html-quickstart",
          // {
          //   type: "category",
          //   label: "Teste",
          //   icon: "arrow-down-wide-narrow",
          //   items: ["teste", "teste1", "teste2"],
          // },
        ],
      },
      // {
      //   type: "category",
      //   label: "Configuration",
      //   icon: "cog",
      //   link: "configuration/overview",
      //   items: [
      //     "configuration/api-reference",
      //     "configuration/navigation",
      //     "configuration/search",
      //     "configuration/authentication",
      //     "configuration/vite-config",
      //   ],
      // },
      // {
      //   type: "category",
      //   label: "Markdown",
      //   icon: "book-open-text",
      //   link: "markdown/overview",
      //   items: ["markdown/mdx", "markdown/admonitions", "markdown/code-blocks"],
      // },
      // {
      //   type: "category",
      //   label: "Guide",
      //   icon: "monitor-check",
      //   items: ["environment-variables", "custom-pages", "using-multiple-apis"],
      // },
      // {
      //   type: "category",
      //   label: "Deployment",
      //   icon: "cloud-upload",
      //   link: "deployment",
      //   items: [
      //     "deploy/cloudflare-pages",
      //     "deploy/github-pages",
      //     "deploy/vercel",
      //     "deploy/direct-upload",
      //   ],
      // },
      // {
      //   type: "category",
      //   label: "Extending",
      //   icon: "blocks",
      //   items: ["custom-plugins", "api-keys"],
      // },
    ],
  },
  apis: {
    type: "file",
    input: "./apis/fs.yaml",
    navigationId: "api",
  },
};

export default config;
